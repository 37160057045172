<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="Shop-container">
        <div class="row1">
           <router-link to="/asesdash" class="close-btn" > Close </router-link>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <h1>Search Property Details</h1>
       </div>
       <div class="Shop-form-container">
           <form id="addShopsForm"  novalidate autocomplete="off">
                <div class="heading">
                    <b><h3>Search Property</h3></b>
                </div>
                <h5><b>search by Category</b></h5>
               <div class="row">
                <div class="col-sm-3">
                    
                   <label for="Splace">Ward
                   </label>
                       <div><select v-model="CatObj.ward" id="ward" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedWards" :key="m.ward_id" id="Hid"  
                            v-bind:value =m.ward_id>
                               <div>{{m.ward }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.wardErr.length > 0">{{ errObj.wardErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="street">Street
                   </label>
                       <div><select v-model="CatObj.street" id="street" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in savedStreets" :key="m.street_id" id="Hid"  
                            v-bind:value =m.street_id>
                               <div>{{m.street_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.stretErr.length > 0">{{ errObj.stretErr[0] }}</p>
               </div>
               <div class="col-sm-3">
                   <label for="cat">Main Category
                   </label>
                       <div><select v-model="CatObj.mainc" id="cat" class="form-control" >
                            <option value="" selected disabled>
                                 Choose
                             </option>
                            <option v-for="m in maincate" :key="m.cat_id" id="Hid"  
                            v-bind:value =m.cat_id>
                               <div>{{m.category_name }}</div> 
                            </option>
                        </select>
                     </div> 

                   <p class="error-mess" v-if="errObj.maincErr.length > 0">{{ errObj.maincErr[0] }}</p>
               </div>
               <div class="Addbtn col-sm-3">
                   <label for="s">
                   </label>
                   <input type="button" value="Search" id="Addpay" class=" btn" @click="SearchByCat()"  /> 
               </div>
               </div>
                <div class="row">                    
                    <div class="col-sm-3">
                        <h5><b>search by property ID</b></h5>
                        <label for="usedn"> Property ID:
                        </label>
                        <div>
                        <input 
                        v-model="CatObj.propsearch" 
                        id="serchprop" 
                        list="sabaPropList" 
                        class="form-control"
                        placeholder="Choose or type a property ID"
                        />
                        
                        <datalist id="sabaPropList">
                        <option 
                            v-for="p in sabaProp" 
                            :key="p.property_id" 
                            :value="p.property_id">
                        </option>
                        </datalist>
                    </div>
                    </div>
                    <div class="Addbtn col-sm-3">
                   <br>
                   <input type="button" value="Search" id="secbtn" class=" btn" @click="SearchById()"  /> 
                    </div>
                </div>
            
           <br>
           <div v-if="showtbl" >
           <div   style="width: 100%; text-align: center;">
                <h3>Property Details</h3>
            </div>
           <div class="row">
            <table style="width: 100%; text-align: left; font-size: small; border-width: 1px; border-style: solid; border-color: #130f40; border-collapse: collapse; margin-left: auto; margin-right: auto;">
               <thead>
                   <tr>   
                        <th>Index</th>
                       <th>Ward</th>
                       <th>Street</th>
                       <th>Category</th>
                       <th>Property ID</th>
                       <th>Owners name</th>
                       <th>Assessment Num</th>
                       <th>Unused num</th>
                       <th>used num</th>
                       <th>Property Value</th>
                       <th>Annual Value</th>
                       <th>Rate</th>
                   </tr>
               </thead>
               <tbody>
                   <tr v-for=" (s,index) in paginatedData" :key="index">
                    <td>{{ (currentPage - 1) * 10 + index + 1 }}</td>
                    <td >{{ getWardName(s.ward_id) }}</td>
                    <td >{{ getStreetName(s.street_id)}}</td>
                       <td>{{ getCategoryName(s.cat_id ) }}</td>
                       <td>{{ s.property_id  }}</td>
                       <td>{{ s.prop_owner  }}</td>
                       <td>{{ s.asses_num }}</td>
                       <td> {{ s.unused_num }}</td>
                       <td>{{ s.used_num }}</td>
                       <td>{{ s.prop_value }}</td>
                       <td>{{ s.yearly_value }}</td>
                       <td>{{ s.prop_rate }}</td>
                   </tr>
               </tbody>
           </table>
           </div>
           <button class="fas fa-backward" @click="prevPage($event)" :disabled="currentPage === 1"></button>
             <span>{{ currentPage }} / {{ totalPages }}</span>
            <button class="fas fa-forward" @click="nextPage($event)" :disabled="currentPage === totalPages"></button>
        </div>
        <div v-else>{{ message }}</div>
           </form>
       </div>
</div>
<!-- end -->
</template>

<script>
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert'
import { mapMutations } from "vuex";
import { mapState } from "vuex";

export default {

    name: "AssessSearchProperty",

    data() {
       return {
           nic:"",
           sabha: "",
           userLevel:"",
           CatObj : { ward:"", street:"",  mainc:"" ,propsearch:""},
           errObj:{ wardErr:[], stretErr:[],maincErr:[]},
           savedWards:[],
           savedStreets:[],
           itemsPerPage: 10,
            currentPage: 1,
            maincate:[],
            sabaProp:[],
            propertydetails:[],
            showtbl:false
       };
   },
   created() {
       this.getDataFromSessionStorage()

   },
   computed:{
       ...mapState(["user"]),
      
    paginatedData() {
        const startIndex = (this.currentPage - 1) * this.itemsPerPage;
        const endIndex = startIndex + this.itemsPerPage;
        
        // Check if this.data is an array before using slice method
        if (Array.isArray(this.propertydetails)) {
        return this.propertydetails.slice(startIndex, endIndex);
        } else {
        // Return empty array or handle loading state
        return [];
        }
  },
  totalPages() {
      return Math.ceil(this.propertydetails.length / this.itemsPerPage);
    },

   },
   async mounted() {
    try {
        this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

    } catch (error) {
        console.error("Error fetching data", error);
    }
},
   methods: {
       ...mapMutations(["setUser"]),

       async getDataFromSessionStorage(){
           const data = JSON.parse(sessionStorage.getItem('userData'))
           if(data){
                           this.nic = data.nic 
                           this.sabha = data.sabha
                           this.userLevel =data.userLevel
                       }
                try{
                this.sabaProp = (await axios.get('/props/' +this.sabha)).data                       
            } catch (error) {
                console.error("Error fetching data", error);
            }
           this.maincate = (await axios.get('/maincat/')).data
           //wards
           this.savedWards= (await axios.get('/wdbys/'+this.sabha)).data;
           //streets
           this.savedStreets = (await axios.get('/stbys/'+this.sabha)).data;

       },
       getWardName(ward_id) {
        const ward = this.savedWards.find(m => m.ward_id === ward_id);
        return ward ? ward.ward : 'Unknown';  // Return 'Unknown' if ward_id is not found
    },
    getStreetName(street_id) {
        const street = this.savedStreets.find(m => m.street_id === street_id);
        return street ? street.street_name : 'Unknown';
    },
    getCategoryName(cat_id) {
        const category = this.maincate.find(m => m.cat_id === cat_id);
        return category ? category.category_name : 'Unknown';
    },

nextPage(event) {
  if (this.currentPage < this.totalPages) {
    this.currentPage++;
  }
  event.preventDefault();
},
prevPage(event) {
  if (this.currentPage > 1) {
    this.currentPage--;
  }
  event.preventDefault();
},
//fectch both
    async fetchData(url) {
        try {
            const response = await axios.get(url);
            this.propertydetails = response.data;
            if (this.propertydetails.length > 0) {
            this.showtbl = true;
            } else {
            this.message = "No such record";
            this.showtbl = false;
            }
        } catch (error) {
            console.error("Error fetching details:", error);
            this.message = "Failed to load details. Please try again later.";
        }
        },

       resetCheckErr: function () {
        this.errObj.wardErr =[];
        this.errObj.stretErr=[];
        this.errObj.maincErr = [];
       },
       checkEmptyErr: function () {
           for (var typeErr in this.errObj) {
               if (this.errObj[typeErr].length != 0) {
                   return false;
               }
           }
           return true;
       },
       checkForm: function () {
           this.resetCheckErr();
           if (!this.CatObj.ward) {
               this.errObj.wardErr.push("required");
           }
           if (!this.CatObj.street) {
               this.errObj.stretErr.push("required");
           }
           // main categories
           if (!this.CatObj.mainc) {
               this.errObj.maincErr.push("required");
           }
       },
       async SearchByCat(e) {
            this.checkForm();
            if (!this.checkEmptyErr()) {
                e.preventDefault();
            } else {
                this.showtbl = false;
            this.message = ""; // Clear any previous messages
            this.propertydetails = []; // Clear previous data
            this.CatObj.propsearch=""
                const url = `/proplist/${this.sabha}/${this.CatObj.ward}/${this.CatObj.street}/${this.CatObj.mainc}`;
                await this.fetchData(url);
        
            }
        },
    async SearchById() {
          this.showtbl = false;
        this.message = ""; // Clear any previous messages
        this.propertydetails = []; // Clear previous data
        this.CatObj.ward="",
        this.CatObj.street="",
        this.CatObj.mainc=""
    try {
        const url = `/sabapropid/${this.sabha}/${this.CatObj.propsearch}`;
        
        // Fetch data from API
        const response = await axios.get(url);

        if (response.data.length > 0) {
        this.propertydetails = response.data;
        this.showtbl = true;
        } else {
        this.message = "No such record";
        this.showtbl = false;
        }
    } catch (error) {
        console.error("Error fetching details:", error);
        this.message = "Failed to load details. Please try again later.";
        this.showtbl = false;
    }
    }
   },
   components: {
        VueBasicAlert
    }
};

</script>

<style scoped>
.row1 {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }
  .close-btn {
  /* align-items: right; */
  background-color:rgb(121, 23, 12);
  border: none;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  color: #f8f4f4;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 8px;
  border-radius: 8px;
  width:6%;
  height: 4%;
  text-align: center;
}

.close-btn:hover {
  color: rgb(220, 18, 18);
  transform: scale(1.2);
  background-color:  #022e2a; 
}

.close-btn:focus {
  outline:none; /* Remove default focus outline */
}
input[type="button"] {
   background: none;
   color: inherit;
   border: none;
   padding: 0;
   font: inherit;
   cursor: pointer;
   outline: inherit;
}
/* shroff form container */
.Shop-container {
   background-color: #ffffff09;
   height: 90vh;
   padding: 1rem 2%;
   font-size: 16px;
}

/* ongoing */
.Shop-container .Shop-form-container {
   background: #fff;
   /* width: 100% !important; */
   
}

.Shop-container .Shop-form-container form {
   /* position: relative;
   left: 40%;
   transform: translate(-50%, 0%); */
   /* max-width: 70rem; */
   width: 100%;
   box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.05);
   border: 0.1rem solid rgba(0, 0, 0, 0.2);
   padding: 2rem;
   border-radius: 0.5rem;
   animation: fadeUp 0.4s linear;
   
}

.Shop-container .Shop-form-container form h3 {
   padding-bottom: 1rem;
   font-size: 2rem;
   text-transform: uppercase;
   color: #130f40;
   margin: 0;
}

.Shop-container .Shop-form-container form .form-control {
    margin: 0.7rem 0;
   border-radius: 0.5rem;
   background: #f7f7f7;
   /* padding: 2rem 1.2rem; */
    font-size: 1.3rem; 
   color: #130f40;
   text-transform: none;
   width: 100%;
   border: margin-bottom; 
} 

.Shop-container .Shop-form-container form label {
    font-size: 1.2rem;
   margin: 0;
   padding: 0;
   float:left;
}

.Shop-container .Shop-form-container form span {
   font-size: 18px;
   padding-left: 5px;
   padding-right: 40px;
}

.Shop-container .Shop-form-container form .btn {
   /* margin: 1rem 0; */
   width: 25%;
   text-align: center;
   font-size: small;
   background-color : #032a2c;
    height: 3rem;
    color: #fffbfb;
  
   
}
/* .Shop-container .Shop-form-container form .table {
   margin: 1rem 0;
   width: 100%;
   text-align: center;
   font-size: small;
   background-color : #e9e4e9;
    height: 3rem;
   
} */
.Shop-container .Shop-form-container form .Addbtn {
   /* margin: 1rem 0; */
   width: 5rem;
   /* height: 2rem; */
   /* text-align: center; */
   /* background-color : #af74a7; */
   /* vertical-align: bottom; */
   /* align-items: bottom; */
   padding-top: 1.9rem;
   padding-left: 3rem;
   
}

.Shop-container .Shop-form-container form p {
   padding-top: 1rem;
   font-size: 1.5rem;
   color: rgb(243, 47, 47);
   margin: 0;
}

.Shop-container .Shop-form-container form p a {
   color: #27ae60;
}

.Shop-container .Shop-form-container form p a:hover {
   color: #130f40;
   text-decoration: underline;
}

.Shop-container .Shop-form-container form .form-group {
   margin: 0;
}

.Shop-container .Shop-form-container form .form-group .error-mess {
   font-size: 1.5rem;
   position: relative;
   color: rgb(243, 47, 47);
   margin: 0;
   padding: 2rem 1.2rem;
}
</style>