import { createWebHistory, createRouter } from "vue-router";
import Login from '../pages/Login.vue';
import Home from '../pages/Home.vue';
import Thank from '../pages/Thank.vue';
import Admin from '../admin/Admin.vue';
import Dashboard from '../pages/Dashboard.vue';
import UserRegister from '../pages/UserRegister.vue';
import AdminLogin from '../pages/AdminLogin.vue';
import ViewEmployee from '../pages/ViewEmployee.vue';
// import IncomeHeads from '../pages/IncomeHeads.vue';
import Profile from '../pages/Profile.vue';
import ChangePassword from '../pages/ChangePassword.vue';
import SabhaProfile from '../pages/SabhaProfile.vue';
import Shroff from '../pages/Shroff.vue';
import Invoice from '../pages/Invoice.vue';
import OnlinePay from '../pages/OnlinePay.vue';
import SubjectDashboard from '../pages/SubjectDashboard.vue';
import ShroffDashboard from '../pages/ShroffDashboard.vue';
import Assign from '../pages/Assign.vue';
import RegisterSadmin from '../pages/RegisterSadmin.vue';
// import AdminDashboard from '../pages/AdminDashboard.vue';
import ProAdminRegister from '../pages/ProadminRegister.vue';
import BankDetails from '../pages/BankDetails.vue';
import DailyIncome from '../pages/DailyIncome.vue';
import SubSummery from '../pages/SubSummery.vue';
import Report from '../pages/Report.vue';
import ShroffIncome from '../pages/ShroffIncome.vue';
import ShopDetails from '../pages/ShopDetails.vue';
import NewShopDetails from '../pages/NewShopDetails.vue';
import SabhaSignature from '../pages/SabhaSignatures.vue';
import AdminShops from '../pages/AdminShops.vue';
import InvoiceView from '../pages/InvoiceView.vue';
import Vote from '../pages/Vote.vue';
import OnlinePayAssesment from '../pages/OnlinePayAssesment.vue';
import OnlineService from '../pages/OnlineService.vue';
import BkCrematoria from '../pages/BkCrematoria.vue';
import Bkgully from '../pages/BkGully.vue';
import ApplicationGully from '../pages/ApplicationGully.vue';
import TempPiv from '../pages/TempPiv.vue';
import ViewCustomers from '../pages/ViewCustomers.vue';
import ShroffIncomeSecretary from '../pages/ShroffIncomeSecretary.vue';
import InvoiceViewSecretary from '../pages/InvoiceViewSecretary.vue';
import DailyIncomeSecretary from '../pages/DailyIncomeSecretary.vue';
import ApplicationGround from '../pages/ApplicationGround.vue';
import SabhaAdmins from '../pages/SabhaAdmins.vue';
import SabhaSecretaries from '../pages/SabhaSecretaries.vue';
import InvoicePrint from '../pages/InvoicePrint.vue';
import MinistryDashboard from '../pages/MinistryDashboard.vue';
import DetaildView from '../pages/DetaildView.vue';
import DetailedVoteView from '../pages/DetailedVoteView.vue';
import DetailedSecView from '../pages/DetailedSecView.vue';
import DetailedEmpView from '../pages/DetailedEmpView.vue';
import ApplicationTownhall from '../pages/ApplicationTownhall.vue';
import OnlineIncome from '../pages/OnlineIncome.vue';
import OnlineDailyincome from '../pages/OnlineDailyincome.vue';
import ApplicationCrematoria from '../pages/ApplicationCrematoria.vue';
import SecretaryPiv from '../pages/SecretaryPiv.vue';
import invoiceLateSec from '../pages/invoiceLateSec.vue';
import MonthlyIncome from '../pages/MonthlyIncome.vue'
import DetaildViewMonthly from "@/pages/DetaildViewMonthly.vue";
import SubSummaryMonth from "@/pages/SubSummaryMonth.vue";
import Estimated from '../pages/Estimated.vue';
import SubSummeryDateRange from '../pages/SubSummeryDateRange.vue';
import onlineInvoice from '../pages/onlineInvoice.vue';
import cancelledInvoice from '../pages/cancelledInvoice.vue';
import LgSix from '../pages/LgSix.vue';
import LgFive from "../pages/LgFive.vue";
import ShopArrearsReport from '../pages/ShopArrearsReport.vue';
import ShopArrearsTwo from '../pages/ShopArrearsTwo.vue';
import ShopArrearsThree from '../pages/ShopArrearsThree.vue';
import LgFiveAnnually from "@/pages/LgFiveAnnually.vue";
import IncomeSummary from "@/pages/IncomeSummary.vue";
import IncomeSummaryByProgram from "@/pages/IncomeSummaryByProgram.vue";
import voucherAdd from "../pages/voucherAdd.vue";
import VoucherDateRange from "@/pages/VoucherDateRange.vue";
// import SubOfficeAdd from "../pages/SubOfficeAdd.vue";
// import index from "/assessment/index.php"
// import SubSummeryAll from "../pages/SubSummeryAll";
import WardsAdd from "../pages/WardsAdd.vue";
import AssessCategories from "../pages/AssessCategories.vue";
import AssessProperty from "../pages/AssessProperty.vue";
import AssessRates from "../pages/AssessRates.vue";
import AssessOwnership from "../pages/AssessOwnership.vue";
import AssessTransfer from "../pages/AssessTransfer.vue";
import AssessDashboard from "@/pages/AssessDashboard.vue";
// import Lgfifteen_seven from "@/pages/Lgfifteen_seven.vue";
// import ExpenseSummary from "@/pages/ExpenseSummary.vue";
// import Lgthirteen from "@/pages/Lgthirteen.vue";
// import Lgfourteen from "@/pages/Lgfourteen.vue";
import AssessQuarterPay from "@/pages/AssessQuarterPay.vue";
import AssessSearchProperty from "@/pages/AssessSearchProperty.vue";
import AssessPIV from "@/pages/AssessPIV.vue";
import AssesstInvoice from "@/pages/AssesstInvoice.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home, 
  },
  {
    path: "/Login",
    name: "Login",
    component: Login, 
  },
  
  {
    path: "/Dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  
  {
    path: "/thank",
    name: "Thank",
    component: Thank,
  },
  {
    path: "/admin",
    name: "Admin",
    component: Admin,
  },
  
 
  //user Registration
  {
    path: "/uregister",
    name: "UserRegister",
    component: UserRegister,
  },
  {
    path: "/admlogin",
    name: "AdminLogin",
    component: AdminLogin,
  },
  {
    path: "/employees",
    name: "ViewEmployee",
    component: ViewEmployee,
  },
  // {
  //   path: "/incomeheads",
  //   name: "IncomeHeads",
  //   component: IncomeHeads,
  // },
  {
    path: "/Profile",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/ChangePassword",
    name: "ChangePassword",
    component: ChangePassword,
  },
  {
    path: "/SabhaProfile",
    name: "SabhaProfile",
    component: SabhaProfile,
  },
  //Shroff
  {
    path: "/Shroff",
    name: "Shroff",
    component: Shroff,
  },
  {
    path: "/Invoice",
    name: "Invoice",
    component: Invoice,
  },
  //OnlinePay
  {
    path: "/OnlinePay",
    name: "OnlinePay",
    component: OnlinePay,
  },
  {
    path: "/SubjectDashboard",
    name: "SubjectDashboard",
    component: SubjectDashboard,
  },
  //ShroffDashboard
  {
    path: "/ShroffDashboard",
    name: "ShroffDashboard",
    component: ShroffDashboard,
  },
  //Assign subjects
  {
    path: "/Assign",
    name: "Assign",
    component: Assign,
  },
  {
    path: "/sabhaadminregister",
    name: "RegisterSadmin",
    component: RegisterSadmin,
  },
 
  {
    path: "/proadminregister",
    name: "ProAdminRegister",
    component: ProAdminRegister,
  },
  {
    path: "/bankdetails",
    name: "BankDetails",
    component: BankDetails,
  },
  {
    path: "/dailyincome",
    name: "DailyIncome",
    component: DailyIncome,
  },
  //DailyIncome
  {
    path: "/subsummery",
    name: "SubSummery",
    component: SubSummery,
  },
  {
    path: "/report",
    name: "Report",
    component: Report,
  },
  
  {
    path: "/shroffincome",
    name: "ShroffIncome",
    component: ShroffIncome,
  },
  {
    path: "/shopdetails",
    name: "ShopDetails",
    component: ShopDetails,
  },
  {
    path: "/newshopdetails",
    name: "NewShopDetails",
    component: NewShopDetails,
  },
  //SabhaSignature
  {
    path: "/sabhasignature",
    name: "SabhaSignature",
    component: SabhaSignature,
  },
  {
    path: "/adminshops",
    name: "AdminShops",
    component: AdminShops,
  },
  {
    path: "/invoiceview/:data",
    name: "InvoiceView",
    component: InvoiceView,props:true
  },
  {
    path: "/incomeheadsnew",
    name: "Vote",
    component:Vote,
  },
  
  {
    path: "/onlineasses",
    name: "OnlinePayAssesment",
    component:OnlinePayAssesment,
  },
  {
    path: "/onlineservices",
    name: "OnlineService",
    component:OnlineService,
  },
  {
    path: "/bkcrematoria",
    name: "BkCrematoria",
    component:BkCrematoria,
  },
  {
    path: "/bkgully",
    name: "Bkgully",
    component:Bkgully,
  },
  {
    path: "/appgully",
    name: "ApplicationGully",
    component:ApplicationGully,
  },
  {
    path: "/temppiv",
    name: "TempPiv",
    component:TempPiv,
  },
  {
    path: "/vcust",
    name: "ViewCustomers",
    component:ViewCustomers,
  },
  {
    path: "/sisecr",
    name: "ShroffIncomeSecretary",
    component:ShroffIncomeSecretary,
  },
  {
    path: "/inviewsec",
    name: "InvoiceViewSecretary",
    component:InvoiceViewSecretary,
  },
  {
    path: "/dailyinsec",
    name: "DailyIncomeSecretary",
    component:DailyIncomeSecretary,
  },
  {
    path: "/appground",
    name: "ApplicationGround",
    component:ApplicationGround,
  },
  {
    path: "/sadvw",
    name: "SabhaAdmins",
    component:SabhaAdmins,
  },
  {
    path: "/sabsecvw",
    name: "SabhaSecretaries",
    component:SabhaSecretaries,
  },
  //
  {
    path: "/invoiceprint",
    name: "InvoicePrint",
    component:InvoicePrint,
  },
  //
  {
    path: "/mindash",
    name: "MinistryDashboard",
    component:MinistryDashboard,
  },
  {
    path: "/detaildview",
    name: "DetaildView",
    component:DetaildView,
  },
  {
    path: "/monthlydetaildview",
    name: "DetaildViewMonthly",
    component:DetaildViewMonthly,
  },
  {
    path: "/detaildvote",
    name: "DetailedVoteView",
    component:DetailedVoteView,
  },
  {
    path: "/detaildsecvw",
    name: "DetailedSecView",
    component:DetailedSecView,
  },
  {
    path: "/detaildempvw",
    name: "DetailedEmpView",
    component:DetailedEmpView,
  },
  {
    path: "/apptown",
    name: "ApplicationTownhall",
    component:ApplicationTownhall,
  },
  {
    path: "/onlineincome",
    name: "OnlineIncome",
    component:OnlineIncome,
  },
  {
    path: "/onlinedaily",
    name: "OnlineDailyincome",
    component:OnlineDailyincome,
  },
  {
    path: "/appcre",
    name: "ApplicationCrematoria",
    component:ApplicationCrematoria,
  },
  {
    path: "/secpiv",
    name: "SecretaryPiv",
    component:SecretaryPiv,
  },
  {
    path: "/involatesec",
    name: "invoiceLateSec",
    component:invoiceLateSec,
  },
  {
    path: "/monthincome",
    name: "MonthlyIncome",
    component:MonthlyIncome,
  },
  {
    path: "/subsummon",
    name: "SubSummaryMonth",
    component:SubSummaryMonth,
  },
  {
    path: "/esti",
    name: "Estimated",
    component:Estimated,
  },
  {
    path: "/subdr",
    name: "SubSummeryDateRange",
    component:SubSummeryDateRange,
  },
  {
    path: "/onlineinvo",
    name: "onlineInvoice",
    component:onlineInvoice,
  },
  //cancelledInvoice
  {
    path: "/vwcanceledinvo",
    name: "cancelledInvoice",
    component:cancelledInvoice,
  },
  {
    path: "/lgfive",
    name: "LgFive",
    component:LgFive,
  },
  {
    path: "/lgfiveannualy",
    name: "LgFiveAnnually",
    component:LgFiveAnnually,
  },
  {
    path: "/lgsix",
    name: "LgSix",
    component:LgSix,
  },
  {
    path: "/imcomesummary",
    name: "IncomeSummary",
    component:IncomeSummary,
  },
  {
    path: "/incomesummarybyprogram",
    name: "IncomeSummaryByProgram",
    component:IncomeSummaryByProgram,
  },
  // {
  //   path: "/lgfifteen_seven",
  //   name: "Lgfifteen_seven",
  //   component:Lgfifteen_seven,
  // },
  // {
  //   path: "/expensesummary",
  //   name: "ExpenseSummary",
  //   component:ExpenseSummary,
  // }, 
  // {
  //   path: "/lgthirteen",
  //   name: "Lgthirteen",
  //   component:Lgthirteen,
  // },
  // {
  //   path: "/lgfourteen",
  //   name: "Lgfourteen",
  //   component:Lgfourteen,
  // },
  {
    path: "/shoparrrepo",
    name: "ShopArrearsReport",
    component:ShopArrearsReport,
  },
  {
    path: "/shoparrtwo",
    name: "ShopArrearsTwo",
    component:ShopArrearsTwo,
  },
  {
    path: "/shoparrthree",
    name: "ShopArrearsThree",
    component:ShopArrearsThree,
  },
  {
    path: "/vouchadd",
    name: "voucherAdd",
    component:voucherAdd,
  },
  {
    path: "/vouchdr",
    name: "VoucherDateRange",
    component:VoucherDateRange,
  },
  // {
  //   path: "/suboff",
  //   name: "SubOfficeAdd",
  //   component:SubOfficeAdd,
  // },
  // { 
  //   path: "/taxassess",
  //   name: "index",
  //   component: () => import('@/assessment/index.php'), 
  // },
//  {
//     path: "/subsumol",
//     name: "SubSummeryAll",
//     component:SubSummeryAll,
//   }, 
  {
    path: "/adward",
    name: "WardsAdd",
    component:WardsAdd,
  },
  {
    path: "/ascat",
    name: "AssessCategories",
    component:AssessCategories,
  },
  {
    path: "/asproperty",
    name: "AssessProperty",
    component:AssessProperty,
  },
  {
    path: "/asrate",
    name: "AssessRates",
    component:AssessRates,
  },
  {
    path: "/owners",
    name: "AssessOwnership",
    component:AssessOwnership,
  },
  {
    path: "/proptrans",
    name: "AssessTransfer",
    component:AssessTransfer,
  },
  {
    path: "/asesdash",
    name: "AssessDashboard",
    component:AssessDashboard,
  },
  {
    path: "/asesqpay",
    name: "AssessQuarterPay",
    component:AssessQuarterPay,
  },
  {
    path: "/aspropsearch",
    name: "AssessSearchProperty",
    component:AssessSearchProperty,
  },
  {
    path: "/aspiv",
    name: "AssessPIV",
    component:AssessPIV,
  },
  {
    path: "/asinvoice",
    name: "AssesstInvoice",
    component:AssesstInvoice,
  },
  {
    path: '/:pathMatch(.*)*',
    component: Login,
    // https://stackoverflow.com/questions/68504803/how-can-i-make-a-catch-all-other-route-in-vue-router-also-catch-the-url-when-p
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;